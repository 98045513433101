:root {
  --color-primary: #00171F;
  --color-secondary: #00A8E8;
  --color-text: #fff;
}

#root {
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 600px) {
  #root {
    font-size: 15px;
  }
}

body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
    height: 100%;
    background-color: var(--color-primary);
}
